<template>
  <v-list raised class="bottomMenu">
    <v-list-item
      v-for="(item, index) in moreItems"
      :key="item + index"
      @click="handleMoreOptions(item)"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icon" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-menu :close-on-content-click="true" :nudge-left="700" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" @click="getPermalink()">
          <v-list-item-icon>
            <v-icon v-text="'mdi-link'" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Permalink") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card>
        <v-list-item>
          <v-tooltip :disabled="isShowTooltip" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="copyText()"> mdi-content-copy </v-icon>
            </template>
            <span>copy</span>
          </v-tooltip>
          <v-list-item-content>
            <v-text-field
              v-model="Permalink"
              readonly
              hide-details
              flat
              solo
              style="width: 500px"
            >
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-menu>
  </v-list>
</template>

<script>
import layer from "@/store/modules/layer";
import HereUtil from "@/util/here";
import { mapState } from "vuex";

export default {
  name: "bottombarmore",
  data: () => ({
    moreItems: [
      { text: "Print", icon: "mdi-printer" },
      { text: "Mail", icon: "mdi-email" },
      { text: "Settings", icon: "mdi-cog-outline" },
      { text: "Help", icon: "mdi-help" },
      { text: "About", icon: "mdi-information" }
    ],
    selectedItem: "",
    Permalink: ""
  }),
  computed: {
    ...mapState({
      isShowTooltip: state => !state.setting.isToolTipsOn
    })
  },
  methods: {
    handleMoreOptions(item) {
      this.selectedItem = item.text;
      if (item.text === "Help") {
        this.$store.commit("changeIsHelpDialogState", true);
      } else if (item.text === "Print") {
        this.$store.commit("changeIsPrintState", true);
      } else if (item.text === "About") {
        this.$store.commit("changeIsAboutDialogState", true);
      } else if (item.text === "Mail") {
        this.getPermalink();
        window.location.href =
          "mailto:" +
          "" +
          "?subject=" +
          " Map from LatLonGO" +
          "&body=" +
          encodeURIComponent(this.Permalink);
      } else if (item.text === "Settings") {
        this.$store.commit("changeIsSettingsDialogState", true);
      }
      this.$store.commit("more/closeMenuList", true);
    },
    copyText() {
      if (this.Permalink) {
        this.$copyText(this.Permalink);
      }
      this.$store.commit("more/closeMenuList", false);
    },
    getPermalink() {
      let activeBaseLayer = layer.state.baseLayers.find(
        layer => layer.isVisible
      ).id;
      let activeOverlayLayers = [];
      layer.state.overlayLayers.forEach(overlayLayer => {
        if (overlayLayer.isVisible) {
          activeOverlayLayers.push(overlayLayer.id);
        }
      });
      let hereUtil = new HereUtil();
      let center = hereUtil.hereMap.getCenter();
      center = [center.lat, center.lng];
      let zoom = hereUtil.hereMap.getZoom();
      this.Permalink =
        "https://" +
        window.location.host +
        // To remove forward slash in the end of pathname
        window.location.pathname.replace(/\/$/, "") +
        "?base=" +
        activeBaseLayer +
        "&overlay=" +
        activeOverlayLayers +
        "&center=" +
        center +
        "&zoom=" +
        zoom;
    }
  }
};
</script>
<style>
.v-application--is-ltr .v-input__prepend-outer {
  margin: 9px 0px 9px 9px !important;
}
</style>
